import * as React from "react";
import { useState } from "react";
import LayoutService from "../../templates/LayoutService";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";

import { Link } from "gatsby";
import "../../styles/components/titles.module.scss";
import "../../styles/components/header.module.scss";
import "../../styles/components/content.module.scss";
import "../../styles/components/contenttoggles.scss";
import { imgBox } from "../../styles/components/boxes.module.scss";
import {
  bannerBlock,
  bannerBlockHeadline,
  bannerBlockTagline,
  bannerBlockTitleSm,
  pageContentBlock,
  pageServiceDivider,
  serviceInfoBlock,
  serviceInfoBtns,
  pageCalloutBlock,
  pageContentMedia,
} from "../../styles/components/blocks.module.scss";
import { hideBlockSM } from "../../styles/components/helpers.module.scss";
import FourHourInfo from "../../components/blocks/FourHourInfo";
import GalleryFourHour from "../../components/galleries/GalleryFourHour";
import { btn } from "../../styles/components/buttons.module.scss";
import WaveRavePageinfo from "../../components/blocks/WaveRave";
import WaveRaveBuyBtn from "../../components/buttons/WaveRaveBuyBtn";
const ThreeHourWaveRaveCharterPage = () => {
  const [isActive, setActive] = useState(true);

  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <LayoutService>
      <Helmet>
        <title>
          Wave Rave | 3-Hour DJ'd Party Boat Charter | Tampa Bay Boating
          Adventures
        </title>
        <meta
          name="description"
          content="A professional DJ will mix beats all night long as your group of up to 12 dance under the stunning city lights of Tampa Bay. This is OUR favorite charter experience. "
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <header className="container">
        <div className="row">
          <div className="col-xxs-12">
            <div className={bannerBlock}>
              <div className={imgBox}>
                <StaticImage
                  alt="Friends talking on a party boat in Tampa Bay, FL."
                  src="../../images/services/header-wave-rave-dj-boat-ride.jpg"
                />
              </div>
              <div className={bannerBlockHeadline}>
                <h1 className={bannerBlockTagline}>
                  Wave Rave | DJ'd Party Boat Charter
                </h1>
                <span className={bannerBlockTitleSm}>Wave Rave</span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main className="container">
        <div className="row">
          <div className="col-xxs-12">
            <GalleryFourHour />
            <div className={serviceInfoBlock}>
              <h2> 3-Hour Nighttime Party Boat Charter </h2>
              <div
                className={serviceInfoBtns}
                style={{
                  display: "flex",
                  textAlign: "center",

                  justifyContent: "flex-start",
                }}
              >
                <WaveRaveBuyBtn />

                <Link
                  to="tel:8137428178"
                  className={`${btn}`}
                  style={{ marginRight: "10px", background: "#0DB0E7" }}
                >
                  Call (813) 742-8178
                </Link>

                {/* <a href="#description" className={btn} onClick={toggleClass}>{isActive ? "Hide Description" : "View Description"}</a> */}
              </div>
              <WaveRavePageinfo />
            </div>
            <div
              id="pageContentContainer"
              className={isActive ? "pageContentOpen" : "noClass"}
            >
              <div className="anchor" id="description"></div>
              <div className={pageContentBlock}>
                <hr className={pageServiceDivider} />
                <h2>Wave Rave | 3-Hour Nighttime Party Boat Charter</h2>
                <p>
                  Bring your crew and dance all night long on our 3-hour Wave
                  Rave party boat experience. We’ll provide a professional DJ
                  who will mix beats all night long, curating the vibe while
                  your crew of up to 12 soak in the city lights and groove to
                  your heart’s content. If you’re in town for a
                  bachelor/bachelorette party, birthday party, vacation, or you
                  just want to experience the best that Tampa Bay has to offer -
                  look no further. This is our favorite year-round boating
                  experience in Tampa Bay and we’re excited to be able to share
                  this with you too!
                </p>
                <div className={pageContentMedia}>
                  <div className={imgBox}>
                    <StaticImage
                      alt="Zach living the life on a party boat near Tampa Bay, FL."
                      src="../../images/services/dj-deck-boat-party-tampa-florida.jpg"
                    />
                  </div>
                </div>
                <h2>
                  What’s Included in a Wave Rave 3-Hour Nighttime Party Boat
                  Charter
                </h2>
                <p>
                  Regardless of the time-of-year, this party is always a great
                  choice, as most people elect to stay on the boat, dance, and
                  keep dry{" "}
                  <a href="https://tampabayboatingadventures.com/blog/is-it-too-cold-for-a-charter-boat-party/">
                    if it’s too chilly to go for a swim.
                  </a>
                </p>
                <h3>Professional Captain</h3>
                <p>
                  Every charter includes
                  a captain that is experienced in navigating the Bay, making
                  your cruise smooth sailing and ensuring you can focus on
                  enjoying the party.
                </p>
                <h3>DJ Services</h3>
                <p>
                  We don’t charge extra for our
                  in-house DJ – it’s all part of the package.
                </p>
                <h3>Convenient Pick-Up</h3>
                <p>
                 We can pick you up from
                  any public dock in the Bay area, meaning you can select one
                  close to your accommodation, making the day even easier.
                </p>
                <div className={pageContentMedia}>
                  <div className={imgBox}>
                    <StaticImage
                      alt="Zach living the life on a party boat near Tampa Bay, FL."
                      src="../../images/services/dj-mixing-beats-boat-party.jpg"
                    />
                  </div>
                </div>
                <div
                  className={serviceInfoBtns}
                  style={{
                    display: "flex",
                    textAlign: "center",

                    justifyContent: "flex-start",
                  }}
                >
                  <WaveRaveBuyBtn />
                  <Link
                    to="tel:8137428178"
                    className={`${btn}`}
                    style={{ marginRight: "10px", background: "#0DB0E7" }}
                  >
                    Call (813) 742-8178
                  </Link>
                </div>
                <hr />
                <h2>Professional DJ Curates Mixes to Fit Your Party</h2>
                <p>
                  The party isn’t complete without a professional DJ, included
                  atx <strong>no additional cost.</strong>When you book, you may
                  also select your perfect kind of music. During the booking
                  process, enter the genre or even specific tracks you want to
                  listen to, and our professional DJ will produce a custom mix
                  for your party. As the party boat cruises through Tampa’s
                  waterways, our premium sound system will keep the party going.
                  Your DJ is a local, professional DJ that has worked with many
                  of the top names in Tampa’s nightlife scene. We can’t give
                  away the exact details, but we can promise they can hold the
                  party down.
                </p>
                <h3>Color-Changing Lights Keep The Party Illuminated</h3>
                <p>
                  Our boat is equipped with color-changing lights installed on
                  the deck, transforming it into a colorful and vibrant dance
                  club. As the party progresses and the night gets darker, the
                  atmosphere builds, creating the perfect party vibe.
                </p>
                <h3>Contact Us to Book a Wave Rave Boat Charter Today!</h3>
                <p>
                  It’s not just a boat ride. It is an experience. If you want to
                  truly make the most of the experience and ensure you don’t
                  miss any of the action, review our guide on the <a href="https://tampabayboatingadventures.com/blog/discover-5-often-forgotten-essentials-summer-party-boat-cruise"> 5
                  Often-Forgotten Essentials for Your Summer Party Boat Cruise.</a>
                  We guarantee that our Wave Rave 3-Hour Nighttime Party Boat
                  Charter is the perfect way to party in Tampa. The experience
                  that you’ll receive with the elevated music, incredible
                  lighting, and night-lights of Tampa makes this our favorite
                  service.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </LayoutService>
  );
};

export default ThreeHourWaveRaveCharterPage;
